export const contentTableConfig = {
  propList: [
    { prop: 'ruleName', label: '方案', minWidth: '100', slotName: 'ruleName' },
    {
      prop: 'shop',
      label: '门店',
      minWidth: '100',
      slotName: 'shop'
    },
    {
      prop: 'role',
      label: '角色',
      minWidth: '100',
      slotName: 'role'
    },
    {
      prop: 'ruleKind',
      label: '方案类型',
      slotName: 'ruleKind'
    },
    {
      prop: 'createTime',
      label: '创建时间',
      minWidth: '80',
      slotName: 'createTime'
    },

    {
      prop: 'updateTime',
      label: '最后修改时间',
      minWidth: '80',
      slotName: 'updateTime'
    },
    {
      prop: 'updateTime',
      label: '最后修改时间',
      minWidth: '80',
      slotName: 'updateTime'
    },
    { label: '操作', minWidth: '150', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
