export const breadcrumbList = [
  {
    name: '提成',
    path: ''
  },
  {
    name: '规则列表',
    path: ''
  }
]

