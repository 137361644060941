<template>
  <div class="add-rank-dialog">
    <page-dialog :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="关联门店">
      <!-- <div class="search">
        <el-input size="small"  @input="handleChange" prefix-icon="search" v-model="searchValue" placeholder="关键字" />
      </div> -->
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <div>状态</div>
        </div>
        <el-scrollbar height="200px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template :key="item" v-for="item in optDataList">
              <el-checkbox @click="checkBoxClick(item)" @change="handleCheckBoxChange" :label="item.shopId">
                <div class="item">
                  <div class="left">
                    <div class="name">{{ item.shopName }}</div>
                  </div>
                  <div class="right">
                    <el-tag v-if="checkList.some(ele => ele == item.shopId)" size="small" class="ml-2" type="success">
                      已分配</el-tag>
                    <el-tag v-else size="small" class="ml-2" type="info"> 未分配</el-tag>
                  </div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { getRoleList, setRuleBind, getRuleBindList } from '@/service/main/commission'
import { getunArchShopsList, getunArchRolesList, getOldBindShop, getOldBindRole, getRuleDetail } from '@/service/main/commission'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    ruleId: {
      type: Number,
      default: -1
    },
    ruleKind: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }


    const shopList = computed(() => store.state.serve.shopList)
    const shopCount = computed(() => store.state.serve.shopCount)

    const shopListInfo = ref([])
    watch(shopList, (n) => {
      // console.log(n);
      shopListInfo.value = n
    }, {
      deep: true
    })

    const handleChange = value => {

    }

    const checkList = ref([])
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    const getBindList = async () => {
      checkList.value = []
      let bindRes = await getRuleBindList(props.ruleId)
      bindRes.data.list.forEach(item => {
        checkList.value.push(item.relateId)
      })
    }


    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }

    const ruleType = computed(() => props.ruleKind)
    const optDataList = ref([])

    const initPage = async () => {
      let type = ruleType.value
      let res
      let oldBind

      const detailres = await getRuleDetail(COMPANY_ID, props.ruleId)
      let oldBindRole = []

      detailres.data.bindItems.forEach(item => {
        oldBindRole.push(item.relateId)
      })


      // if (type == 1 || type == 2) {
      //   res = await getunArchShopsList()
      //   if (oldBindRole.length > 0) {
      //     oldBind = await getOldBindShop({
      //       shopIds: oldBindRole
      //     })
      //   }

      // } else {
      //   res = await getunArchRolesList()
      //   if (oldBindRole.length > 0) {
      //     oldBind = await getOldBindRole({
      //       lvIds: oldBindRole
      //     })
      //   }

      // }
      res = await getunArchShopsList()
      if (oldBindRole.length > 0) {
        oldBind = await getOldBindShop({
          shopIds: oldBindRole
        })
      }

      optDataList.value = res.data.list
      let oldBindList = []
      if (oldBindRole.length > 0) {
        oldBindList = oldBind.data.list
      }

      oldBindList.forEach(ele => {
        optDataList.value.push(ele)
      })

      getBindList()
    }
    initPage()

    const handleCheckBoxChange = (flag) => {
      let res
      nextTick(async () => {
        let obj = {
          companyId: COMPANY_ID,
          kind: 1,
          relateId: currentEditShop.id,
          ruleId: props.ruleId
        }
        if (flag) {
          res = await setRuleBind(obj)
          // console.log('关联');
        } else {
          res = await setRuleBind(obj)
          // console.log('取消关联');
        }
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          emit('refreshList')
        } else {
          ElMessage.error('操作失败！')
        }
        getBindList()

      })
    }

    const handleCheckChange = (value) => {
      // console.log(value);
    }

    const checkBoxClick = (item) => {
      // console.log(item);
      currentEditShop.id = item.shopId
    }






    watch(() => props.ruleId, (n) => {
      getBindList()
    }, {
      deep: true
    })


    const searchValue = ref('')

    return {
      optDataList,
      ruleType,
      handleChange,
      shopListInfo,
      searchValue,
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      shopList,
      shopCount,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentEditShop,
      // shopCardBindList,
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;


          }
        }
      }
    }
  }
}
</style>




