<template>
  <div class="top-content">
    <div class="left">
      <div @click="handleRuleClick(1)" :class="{ active: optIndex.some(ele => ele == 1) }" class="item">
        <div class="type">消耗规则</div>
        <div class="num">{{ commissionxiaohaoCount }}种</div>
      </div>
      <div @click="handleRuleClick(2)" :class="{ active: optIndex.some(ele => ele == 2) }" class="item">
        <div class="type">销售规则</div>
        <div class="num">{{ commissionxiaoshouCount }}种</div>
      </div>
    </div>

    <div class="right">
      <div class="item">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            创建门店规则
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleAddXiaohao(1)">+ 设置门店消耗规则</el-dropdown-item>
              <el-dropdown-item @click="handleAddXiaoshou(2)" divided>+ 设置门店销售规则</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="item">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            创建角色规则
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleAddXiaohao(3)">+ 设置角色消耗规则</el-dropdown-item>
              <el-dropdown-item @click="handleAddXiaoshou(4)" divided>+ 设置角色销售规则</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import emitter from '@/utils/eventbus'
import { defineComponent, reactive, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const store = useStore()
    const router = useRouter()

    // 设置角色消耗规则
    const handleAddXiaohao = (ruleKind) => {
      router.push({
        path: '/main/commission/addConsumeRule',
        query: {
          ruleKind
        }
      })
    }

    // 设置角色销售规则
    const handleAddXiaoshou = (ruleKind) => {
      router.push({
        path: '/main/commission/addSellRule',
        query: {
          ruleKind
        }
      })
    }

    const commissionxiaohaoCount = computed(() => store.state.commission.commissionxiaohaoCount)
    const commissionxiaoshouCount = computed(() => store.state.commission.commissionxiaoshouCount)
    const handleRuleClick = value => {
      let index = optIndex.value.findIndex(ele => ele == value)
      if (index == -1) {
        optIndex.value = [value]
      } else {
        optIndex.value.splice(index, 1)
      }

      // console.log(optIndex.value);

      emitter.emit('refreshCommissionPageRuleList', optIndex.value)
    }

    const optIndex = ref([])


    return {
      optIndex,
      handleRuleClick,
      handleAddXiaohao,
      handleAddXiaoshou,
      commissionxiaohaoCount,
      commissionxiaoshouCount
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .active {
    border: 1px solid #ff6600 !important;
    color: #ff6600 !important;
  }

  .right {
    display: flex;

    &:deep(.el-dropdown) {
      color: rgba(42, 130, 228, 1);
    }

    .item {
      cursor: pointer;
      margin-right: 10px;
      width: 173px;
      height: 41px;
      color: rgba(42, 130, 228, 1);
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(42, 130, 228, 1);
      border-radius: 5px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .left {
    display: flex;

    .item {
      margin-right: 10px;
      width: 158px;
      height: 48px;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(204, 204, 204, 1);
      border-radius: 5px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      .num {
        color: rgba(42, 130, 228, 1);
      }
    }

  }
}
</style>




