<template>
  <div class="rule-list">
    <pageContent :contentTableConfig="contentTableConfig" :isShowTooltip="false" :dataList="commissionListInfo">
      <template #handler="scope">
        <div class="handle-btns">
          <el-button @click="handleItemClick(scope.row)" plain size="mini">详情</el-button>
          <el-button @click="handleUpdateClick(scope.row)" plain size="mini">编辑</el-button>
          <el-button @click="handleRemoveClick(scope.row)" plain size="mini">删除</el-button>
          <el-button @click="handleFenpeiClick(scope.row)" plain size="mini">分配</el-button>
        </div>
      </template>
      <template #runStatus="scope">
        <el-tag v-if="scope.row.runStatus == 1" size="small" class="ml-2" type="success"> 已分配</el-tag>
        <el-tag v-else class="ml-2" type="info">未分配</el-tag>

        <!-- <template v-if="scope.row.useQty > 0 && scope.row.runStatus == 1">
          <el-switch active-color="#409EFF" disabled :active-value="1" :inactive-value="0"
            v-model="scope.row.runStatus" />
        </template>
        <template v-else>

          <el-switch active-color="#409EFF" @click="handleStateClick(scope.row)" :active-value="1" :inactive-value="0"
            v-model="scope.row.runStatus" />
        </template> -->
        <!-- <el-switch :disabled="scope.row.useQty > 0 && scope.row.runStatus == 1" @click="handleStateClick(scope.row)"
          active-color="#409EFF" :active-value="1" :inactive-value="0" v-model="scope.row.runStatus" /> -->
      </template>
      <template #updateTime="scope">
        <div v-if="scope.row.updateTime == null">-</div>
        <div v-else>{{ scope.row.updateTime }}</div>
      </template>
      <template #ruleKind="scope">
        <template v-if="scope.row.ruleKind == 1">
          门店消耗提成
        </template>
        <template v-if="scope.row.ruleKind == 2">
          门店销售提成
        </template>
        <template v-if="scope.row.ruleKind == 3">
          员工消耗提成
        </template>
        <template v-if="scope.row.ruleKind == 4">
          员工销售提成
        </template>
      </template>
      <template #shop="scope">
        <template v-if="scope.row.ruleKind == 1 || scope.row.ruleKind == 2">
          <div>
            <el-tag size="small"> {{ scope.row.useQty }}</el-tag>
          </div>
        </template>
        <template>
          <div></div>
        </template>
      </template>
      <template #role="scope">
        <template v-if="scope.row.ruleKind == 3 || scope.row.ruleKind == 4">
          <div>
            <el-tag size="small"> {{ scope.row.useQty }}</el-tag>
          </div>
        </template>
        <template>
          <div></div>
        </template>
      </template>
    </pageContent>
    <distribute :ruleKind="ruleKind" @refreshList='refreshList' v-if="dialogVisible" :ruleId="curretnRule.id"
      @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible">
    </distribute>
    <roleDistribute :ruleKind="ruleKind" @refreshList='refreshList' v-if="dialogRoleVisible" :ruleId="curretnRule.sid"
      @changeDialogVisible="changeRoleDialogVisible" :dialogVisible="dialogRoleVisible"></roleDistribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, nextTick, onUnmounted } from 'vue'
import pageContent from '@/components/page-content2/src/page-content.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { contentTableConfig } from './config'
import distribute from './cpns/distribute-dialog/distribute-dialog'
import roleDistribute from './cpns/role-distribute-dialog/role-distribute-dialog.vue'
import { COMPANY_ID } from '@/common/constant'
import { setRuleRuleState, removeRuleRuleState } from '@/service/main/commission'
import { ElMessage } from 'element-plus'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    pageContent,
    distribute,
    roleDistribute
  },
  setup() {
    const store = useStore()
    const flag = ref(true)
    const router = useRouter()
    const currentPage = reactive({
      pagr: 1,
      pagesize: 10
    })

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const dialogRoleVisible = ref(false)
    const changeRoleDialogVisible = (flag) => {
      dialogRoleVisible.value = flag
    }
    const curretnRule = reactive({
      id: '',
      sid: ''
    })


    const ruleKind = ref()
    const handleFenpeiClick = (item) => {
      // console.log(item);
      ruleKind.value = item.ruleKind
      if (item.ruleKind == 1 || item.ruleKind == 2) {
        curretnRule.id = item.id
        dialogVisible.value = true
      } else {
        curretnRule.sid = item.id
        dialogRoleVisible.value = true
      }

    }

    const refreshList = () => {
      initPage()
    }

    const handleItemClick = (item) => {
      // console.log(item);
      router.push({
        path: '/main/commission/detail',
        query: {
          ruleId: item.id,
          ruleKind: item.ruleKind
        }
      })
    }

    const handleUpdateClick = item => {
      router.push({
        path: '/main/commission/update',
        query: {
          ruleId: item.id,
          ruleKind: item.ruleKind
        }
      })
    }

    const commissionListInfo = ref([])
    const commissionList = computed(() => store.state.commission.commissionList)

    watch(commissionList, (n) => {
      commissionListInfo.value = n
    }, {
      deep: true
    })
    const initPage = () => {
      store.dispatch('commission/getCommissionListAction', COMPANY_ID)
    }
    initPage()

    const currentEditRule = reactive({
      id: '',

    })
    const handleStateClick = item => {
      // console.log(item.runStatus);
      // if (item.useQty > 0&& item.runStatus==0||item.useQty > 0&& item.runStatus==1) return
      currentEditRule.id = item.id
      setRuleRuleState(item.id, item.runStatus).then(res => {
        if (res.code == 0) {

          ElMessage({
            message: '操作成功！',
            type: 'success',
          })
        } else {
          ElMessage.error('操作失败')
        }
      })
    }

    const handleRemoveClick = async (item) => {
      const res = await removeRuleRuleState(item.id, COMPANY_ID)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        initPage()
      } else {
        ElMessage({
          message: '规则使用中,无法删除!',
          type: 'warning',
        })
      }
    }

    onUnmounted(() => {
      emitter.off('refreshCommissionPageRuleList')
    })

    emitter.on('refreshCommissionPageRuleList', optIndex => {
      let ruleList = commissionList.value
      let newRuleList = []
      // console.log(optIndex);
      // console.log(ruleList);
      if (optIndex.length == 2 || optIndex.length == 0) {
        commissionListInfo.value = ruleList
        return
      } else if (optIndex[0] == 1) {
        // console.log(21);
        newRuleList = ruleList.filter(ele => ele.ruleKind == 1 || ele.ruleKind == 3)
      } else if (optIndex[0] == 2) {
        newRuleList = ruleList.filter(ele => ele.ruleKind == 2 || ele.ruleKind == 4)
      }

      commissionListInfo.value = newRuleList
    })

    return {
      commissionListInfo,
      ruleKind,
      refreshList,
      handleRemoveClick,
      currentEditRule,
      handleStateClick,
      handleUpdateClick,
      handleItemClick,
      changeRoleDialogVisible,
      dialogRoleVisible,
      changeDialogVisible,
      contentTableConfig,
      currentPage,
      commissionList,
      flag,
      dialogVisible,
      curretnRule,
      handleFenpeiClick
    }

  }
})
</script>

<style scoped lang="less">
.rule-list {
  margin-top: 20px;

  .use-num {
    background-color: rgba(121, 72, 234, 1);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
  }

  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }
}
</style>




